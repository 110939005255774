import validate from "/codebuild/output/src1732223440/src/notionforms/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45check_45auth_45global from "/codebuild/output/src1732223440/src/notionforms/client/middleware/01.check-auth.global.js";
import custom_45domain_45global from "/codebuild/output/src1732223440/src/notionforms/client/middleware/custom-domain.global.js";
import manifest_45route_45rule from "/codebuild/output/src1732223440/src/notionforms/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45check_45auth_45global,
  custom_45domain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/admin.js"),
  auth: () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/auth.js"),
  guest: () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/guest.js"),
  moderator: () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/moderator.js"),
  "notion-connection": () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/notion-connection.js"),
  subscribed: () => import("/codebuild/output/src1732223440/src/notionforms/client/middleware/subscribed.js")
}