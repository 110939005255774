import { default as affiliatesITPHtqjVIGMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsEJwI1Y8GqcMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngos4mBEq8upuyMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93LT4O6BYr5rMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexqx1Rz6HHmuMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93L79vfNyQvzMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexadREXca2TZMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93FqpD3lf4oiMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93wnONet4wSrMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as edityrppD5PphmMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexaxd7X0553WMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsncOeVLtOzyMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexHTpkk55RNfMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexnpmQ1W2frAMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareedhpe3HzRsMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showcuIZK55adKMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexgvDuulzYHuMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsVMTRkumDuyMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guideslGHhblfayAMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/guides.vue?macro=true";
import { default as home5ggeAkNEx5Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexHqRxAH3HiSMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsLBoXt8SoR4Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginpl0CJvx143Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templates8uYUUBZudOMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersZy7VzKHnB8Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93ZKTOTvjXESMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackG4yMe6MC68Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackFfHG5HYfJTMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackZ0kBFwUKEdMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersS8szdHH9IxMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93oHzl7661pSMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexwXWfeN5nr2Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricing362JctQAySMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyV8qxRt9RAAMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerJFJcT7eWEHMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokenshE5HXNnCAgMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountmZzsl8MFnsMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminFjdQmxoh8oMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billing8Ze48f2JQkMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsXBQtzT02wkMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainB9eMBh4ZEhMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsVKLmy3fBuPMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexzDrss6wf4MMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordBnpopLpIuSMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileUi29RLFsWSMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspaceszXlWrBhizwMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsBXRs3U0mcaMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorNURpaur0bxMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successTAZZIgHI5yMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionswMPWdhQZQ1Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editBrCEfKIpfgMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexcZZ1Okx2q4Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexBRM1fYBND6Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareg7lPwlU3qnMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showEUtIqipxc2Meta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexh2imfXEyqSMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexyPEwOaP3gmMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsz0dB5CTlqKMeta } from "/codebuild/output/src1732223440/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesITPHtqjVIGMeta?.name ?? "affiliates",
    path: affiliatesITPHtqjVIGMeta?.path ?? "/affiliates",
    meta: affiliatesITPHtqjVIGMeta || {},
    alias: affiliatesITPHtqjVIGMeta?.alias || [],
    redirect: affiliatesITPHtqjVIGMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsEJwI1Y8GqcMeta?.name ?? "database-views",
    path: database_45viewsEJwI1Y8GqcMeta?.path ?? "/database-views",
    meta: database_45viewsEJwI1Y8GqcMeta || {},
    alias: database_45viewsEJwI1Y8GqcMeta?.alias || [],
    redirect: database_45viewsEJwI1Y8GqcMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngos4mBEq8upuyMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngos4mBEq8upuyMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngos4mBEq8upuyMeta || {},
    alias: discount_45students_45academics_45ngos4mBEq8upuyMeta?.alias || [],
    redirect: discount_45students_45academics_45ngos4mBEq8upuyMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LT4O6BYr5rMeta?.name ?? "features-slug",
    path: _91slug_93LT4O6BYr5rMeta?.path ?? "/features/:slug()",
    meta: _91slug_93LT4O6BYr5rMeta || {},
    alias: _91slug_93LT4O6BYr5rMeta?.alias || [],
    redirect: _91slug_93LT4O6BYr5rMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqx1Rz6HHmuMeta?.name ?? "features",
    path: indexqx1Rz6HHmuMeta?.path ?? "/features",
    meta: indexqx1Rz6HHmuMeta || {},
    alias: indexqx1Rz6HHmuMeta?.alias || [],
    redirect: indexqx1Rz6HHmuMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L79vfNyQvzMeta?.name ?? "form-templates-slug",
    path: _91slug_93L79vfNyQvzMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93L79vfNyQvzMeta || {},
    alias: _91slug_93L79vfNyQvzMeta?.alias || [],
    redirect: _91slug_93L79vfNyQvzMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexadREXca2TZMeta?.name ?? "form-templates",
    path: indexadREXca2TZMeta?.path ?? "/form-templates",
    meta: indexadREXca2TZMeta || {},
    alias: indexadREXca2TZMeta?.alias || [],
    redirect: indexadREXca2TZMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqpD3lf4oiMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93FqpD3lf4oiMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93FqpD3lf4oiMeta || {},
    alias: _91slug_93FqpD3lf4oiMeta?.alias || [],
    redirect: _91slug_93FqpD3lf4oiMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wnONet4wSrMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93wnONet4wSrMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93wnONet4wSrMeta || {},
    alias: _91slug_93wnONet4wSrMeta?.alias || [],
    redirect: _91slug_93wnONet4wSrMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: edityrppD5PphmMeta?.name ?? "forms-slug-edit",
    path: edityrppD5PphmMeta?.path ?? "/forms/:slug()/edit",
    meta: edityrppD5PphmMeta || {},
    alias: edityrppD5PphmMeta?.alias || [],
    redirect: edityrppD5PphmMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexaxd7X0553WMeta?.name ?? "forms-slug",
    path: indexaxd7X0553WMeta?.path ?? "/forms/:slug()",
    meta: indexaxd7X0553WMeta || {},
    alias: indexaxd7X0553WMeta?.alias || [],
    redirect: indexaxd7X0553WMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showcuIZK55adKMeta?.name ?? undefined,
    path: showcuIZK55adKMeta?.path ?? "/forms/:slug()/show",
    meta: showcuIZK55adKMeta || {},
    alias: showcuIZK55adKMeta?.alias || [],
    redirect: showcuIZK55adKMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsncOeVLtOzyMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsncOeVLtOzyMeta?.path ?? "analytics",
    meta: analyticsncOeVLtOzyMeta || {},
    alias: analyticsncOeVLtOzyMeta?.alias || [],
    redirect: analyticsncOeVLtOzyMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexHTpkk55RNfMeta?.name ?? "forms-slug-show",
    path: indexHTpkk55RNfMeta?.path ?? "",
    meta: indexHTpkk55RNfMeta || {},
    alias: indexHTpkk55RNfMeta?.alias || [],
    redirect: indexHTpkk55RNfMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexnpmQ1W2frAMeta?.name ?? "forms-slug-show-integrations",
    path: indexnpmQ1W2frAMeta?.path ?? "integrations",
    meta: indexnpmQ1W2frAMeta || {},
    alias: indexnpmQ1W2frAMeta?.alias || [],
    redirect: indexnpmQ1W2frAMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareedhpe3HzRsMeta?.name ?? "forms-slug-show-share",
    path: shareedhpe3HzRsMeta?.path ?? "share",
    meta: shareedhpe3HzRsMeta || {},
    alias: shareedhpe3HzRsMeta?.alias || [],
    redirect: shareedhpe3HzRsMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexgvDuulzYHuMeta?.name ?? "forms-create",
    path: indexgvDuulzYHuMeta?.path ?? "/forms/create",
    meta: indexgvDuulzYHuMeta || {},
    alias: indexgvDuulzYHuMeta?.alias || [],
    redirect: indexgvDuulzYHuMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsVMTRkumDuyMeta?.name ?? "forms-uploads",
    path: uploadsVMTRkumDuyMeta?.path ?? "/forms/uploads",
    meta: uploadsVMTRkumDuyMeta || {},
    alias: uploadsVMTRkumDuyMeta?.alias || [],
    redirect: uploadsVMTRkumDuyMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guideslGHhblfayAMeta?.name ?? "guides",
    path: guideslGHhblfayAMeta?.path ?? "/guides",
    meta: guideslGHhblfayAMeta || {},
    alias: guideslGHhblfayAMeta?.alias || [],
    redirect: guideslGHhblfayAMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: home5ggeAkNEx5Meta?.name ?? "home",
    path: home5ggeAkNEx5Meta?.path ?? "/home",
    meta: home5ggeAkNEx5Meta || {},
    alias: home5ggeAkNEx5Meta?.alias || [],
    redirect: home5ggeAkNEx5Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexHqRxAH3HiSMeta?.name ?? "index",
    path: indexHqRxAH3HiSMeta?.path ?? "/",
    meta: indexHqRxAH3HiSMeta || {},
    alias: indexHqRxAH3HiSMeta?.alias || [],
    redirect: indexHqRxAH3HiSMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsLBoXt8SoR4Meta?.name ?? "integrations",
    path: integrationsLBoXt8SoR4Meta?.path ?? "/integrations",
    meta: integrationsLBoXt8SoR4Meta || {},
    alias: integrationsLBoXt8SoR4Meta?.alias || [],
    redirect: integrationsLBoXt8SoR4Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginpl0CJvx143Meta?.name ?? "login",
    path: loginpl0CJvx143Meta?.path ?? "/login",
    meta: loginpl0CJvx143Meta || {},
    alias: loginpl0CJvx143Meta?.alias || [],
    redirect: loginpl0CJvx143Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templates8uYUUBZudOMeta?.name ?? "my-templates",
    path: my_45templates8uYUUBZudOMeta?.path ?? "/my-templates",
    meta: my_45templates8uYUUBZudOMeta || {},
    alias: my_45templates8uYUUBZudOMeta?.alias || [],
    redirect: my_45templates8uYUUBZudOMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersZy7VzKHnB8Meta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersZy7VzKHnB8Meta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersZy7VzKHnB8Meta || {},
    alias: notion_45ambassadors_45influencersZy7VzKHnB8Meta?.alias || [],
    redirect: notion_45ambassadors_45influencersZy7VzKHnB8Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZKTOTvjXESMeta?.name ?? "notion-form-slug",
    path: _91slug_93ZKTOTvjXESMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93ZKTOTvjXESMeta || {},
    alias: _91slug_93ZKTOTvjXESMeta?.alias || [],
    redirect: _91slug_93ZKTOTvjXESMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackG4yMe6MC68Meta?.name ?? "notion-callback",
    path: callbackG4yMe6MC68Meta?.path ?? "/notion/callback",
    meta: callbackG4yMe6MC68Meta || {},
    alias: callbackG4yMe6MC68Meta?.alias || [],
    redirect: callbackG4yMe6MC68Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackFfHG5HYfJTMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackFfHG5HYfJTMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackFfHG5HYfJTMeta || {},
    alias: guest_45callbackFfHG5HYfJTMeta?.alias || [],
    redirect: guest_45callbackFfHG5HYfJTMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackZ0kBFwUKEdMeta?.name ?? "oauth-callback",
    path: callbackZ0kBFwUKEdMeta?.path ?? "/oauth/callback",
    meta: callbackZ0kBFwUKEdMeta || {},
    alias: callbackZ0kBFwUKEdMeta?.alias || [],
    redirect: callbackZ0kBFwUKEdMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersS8szdHH9IxMeta?.name ?? "partners",
    path: partnersS8szdHH9IxMeta?.path ?? "/partners",
    meta: partnersS8szdHH9IxMeta || {},
    alias: partnersS8szdHH9IxMeta?.alias || [],
    redirect: partnersS8szdHH9IxMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93oHzl7661pSMeta?.name ?? "password-reset-token",
    path: _91token_93oHzl7661pSMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93oHzl7661pSMeta || {},
    alias: _91token_93oHzl7661pSMeta?.alias || [],
    redirect: _91token_93oHzl7661pSMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexwXWfeN5nr2Meta?.name ?? "password-reset",
    path: indexwXWfeN5nr2Meta?.path ?? "/password/reset",
    meta: indexwXWfeN5nr2Meta || {},
    alias: indexwXWfeN5nr2Meta?.alias || [],
    redirect: indexwXWfeN5nr2Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricing362JctQAySMeta?.name ?? "pricing",
    path: pricing362JctQAySMeta?.path ?? "/pricing",
    meta: pricing362JctQAySMeta || {},
    alias: pricing362JctQAySMeta?.alias || [],
    redirect: pricing362JctQAySMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyV8qxRt9RAAMeta?.name ?? "privacy-policy",
    path: privacy_45policyV8qxRt9RAAMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyV8qxRt9RAAMeta || {},
    alias: privacy_45policyV8qxRt9RAAMeta?.alias || [],
    redirect: privacy_45policyV8qxRt9RAAMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerJFJcT7eWEHMeta?.name ?? "register",
    path: registerJFJcT7eWEHMeta?.path ?? "/register",
    meta: registerJFJcT7eWEHMeta || {},
    alias: registerJFJcT7eWEHMeta?.alias || [],
    redirect: registerJFJcT7eWEHMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsBXRs3U0mcaMeta?.name ?? undefined,
    path: settingsBXRs3U0mcaMeta?.path ?? "/settings",
    meta: settingsBXRs3U0mcaMeta || {},
    alias: settingsBXRs3U0mcaMeta?.alias || [],
    redirect: settingsBXRs3U0mcaMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokenshE5HXNnCAgMeta?.name ?? "settings-access-tokens",
    path: access_45tokenshE5HXNnCAgMeta?.path ?? "access-tokens",
    meta: access_45tokenshE5HXNnCAgMeta || {},
    alias: access_45tokenshE5HXNnCAgMeta?.alias || [],
    redirect: access_45tokenshE5HXNnCAgMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountmZzsl8MFnsMeta?.name ?? "settings-account",
    path: accountmZzsl8MFnsMeta?.path ?? "account",
    meta: accountmZzsl8MFnsMeta || {},
    alias: accountmZzsl8MFnsMeta?.alias || [],
    redirect: accountmZzsl8MFnsMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminFjdQmxoh8oMeta?.name ?? "settings-admin",
    path: adminFjdQmxoh8oMeta?.path ?? "admin",
    meta: adminFjdQmxoh8oMeta || {},
    alias: adminFjdQmxoh8oMeta?.alias || [],
    redirect: adminFjdQmxoh8oMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billing8Ze48f2JQkMeta?.name ?? "settings-billing",
    path: billing8Ze48f2JQkMeta?.path ?? "billing",
    meta: billing8Ze48f2JQkMeta || {},
    alias: billing8Ze48f2JQkMeta?.alias || [],
    redirect: billing8Ze48f2JQkMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsXBQtzT02wkMeta?.name ?? "settings-connections",
    path: connectionsXBQtzT02wkMeta?.path ?? "connections",
    meta: connectionsXBQtzT02wkMeta || {},
    alias: connectionsXBQtzT02wkMeta?.alias || [],
    redirect: connectionsXBQtzT02wkMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainB9eMBh4ZEhMeta?.name ?? "settings-custom-domain",
    path: custom_45domainB9eMBh4ZEhMeta?.path ?? "custom-domain",
    meta: custom_45domainB9eMBh4ZEhMeta || {},
    alias: custom_45domainB9eMBh4ZEhMeta?.alias || [],
    redirect: custom_45domainB9eMBh4ZEhMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settingsVKLmy3fBuPMeta?.name ?? "settings-email-settings",
    path: email_45settingsVKLmy3fBuPMeta?.path ?? "email-settings",
    meta: email_45settingsVKLmy3fBuPMeta || {},
    alias: email_45settingsVKLmy3fBuPMeta?.alias || [],
    redirect: email_45settingsVKLmy3fBuPMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: indexzDrss6wf4MMeta?.name ?? "settings",
    path: indexzDrss6wf4MMeta?.path ?? "",
    meta: indexzDrss6wf4MMeta || {},
    alias: indexzDrss6wf4MMeta?.alias || [],
    redirect: indexzDrss6wf4MMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordBnpopLpIuSMeta?.name ?? "settings-password",
    path: passwordBnpopLpIuSMeta?.path ?? "password",
    meta: passwordBnpopLpIuSMeta || {},
    alias: passwordBnpopLpIuSMeta?.alias || [],
    redirect: passwordBnpopLpIuSMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileUi29RLFsWSMeta?.name ?? "settings-profile",
    path: profileUi29RLFsWSMeta?.path ?? "profile",
    meta: profileUi29RLFsWSMeta || {},
    alias: profileUi29RLFsWSMeta?.alias || [],
    redirect: profileUi29RLFsWSMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaceszXlWrBhizwMeta?.name ?? "settings-workspaces",
    path: workspaceszXlWrBhizwMeta?.path ?? "workspaces",
    meta: workspaceszXlWrBhizwMeta || {},
    alias: workspaceszXlWrBhizwMeta?.alias || [],
    redirect: workspaceszXlWrBhizwMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorNURpaur0bxMeta?.name ?? "subscriptions-error",
    path: errorNURpaur0bxMeta?.path ?? "/subscriptions/error",
    meta: errorNURpaur0bxMeta || {},
    alias: errorNURpaur0bxMeta?.alias || [],
    redirect: errorNURpaur0bxMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successTAZZIgHI5yMeta?.name ?? "subscriptions-success",
    path: successTAZZIgHI5yMeta?.path ?? "/subscriptions/success",
    meta: successTAZZIgHI5yMeta || {},
    alias: successTAZZIgHI5yMeta?.alias || [],
    redirect: successTAZZIgHI5yMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionswMPWdhQZQ1Meta?.name ?? "terms-conditions",
    path: terms_45conditionswMPWdhQZQ1Meta?.path ?? "/terms-conditions",
    meta: terms_45conditionswMPWdhQZQ1Meta || {},
    alias: terms_45conditionswMPWdhQZQ1Meta?.alias || [],
    redirect: terms_45conditionswMPWdhQZQ1Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editBrCEfKIpfgMeta?.name ?? "views-slug-edit",
    path: editBrCEfKIpfgMeta?.path ?? "/views/:slug()/edit",
    meta: editBrCEfKIpfgMeta || {},
    alias: editBrCEfKIpfgMeta?.alias || [],
    redirect: editBrCEfKIpfgMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexcZZ1Okx2q4Meta?.name ?? "views-slug",
    path: indexcZZ1Okx2q4Meta?.path ?? "/views/:slug()",
    meta: indexcZZ1Okx2q4Meta || {},
    alias: indexcZZ1Okx2q4Meta?.alias || [],
    redirect: indexcZZ1Okx2q4Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showEUtIqipxc2Meta?.name ?? undefined,
    path: showEUtIqipxc2Meta?.path ?? "/views/:slug()/show",
    meta: showEUtIqipxc2Meta || {},
    alias: showEUtIqipxc2Meta?.alias || [],
    redirect: showEUtIqipxc2Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexBRM1fYBND6Meta?.name ?? "views-slug-show",
    path: indexBRM1fYBND6Meta?.path ?? "",
    meta: indexBRM1fYBND6Meta || {},
    alias: indexBRM1fYBND6Meta?.alias || [],
    redirect: indexBRM1fYBND6Meta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareg7lPwlU3qnMeta?.name ?? "views-slug-show-share",
    path: shareg7lPwlU3qnMeta?.path ?? "share",
    meta: shareg7lPwlU3qnMeta || {},
    alias: shareg7lPwlU3qnMeta?.alias || [],
    redirect: shareg7lPwlU3qnMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexh2imfXEyqSMeta?.name ?? "views-create",
    path: indexh2imfXEyqSMeta?.path ?? "/views/create",
    meta: indexh2imfXEyqSMeta || {},
    alias: indexh2imfXEyqSMeta?.alias || [],
    redirect: indexh2imfXEyqSMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexyPEwOaP3gmMeta?.name ?? "views",
    path: indexyPEwOaP3gmMeta?.path ?? "/views",
    meta: indexyPEwOaP3gmMeta || {},
    alias: indexyPEwOaP3gmMeta?.alias || [],
    redirect: indexyPEwOaP3gmMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsz0dB5CTlqKMeta?.name ?? "views-uploads",
    path: uploadsz0dB5CTlqKMeta?.path ?? "/views/uploads",
    meta: uploadsz0dB5CTlqKMeta || {},
    alias: uploadsz0dB5CTlqKMeta?.alias || [],
    redirect: uploadsz0dB5CTlqKMeta?.redirect,
    component: () => import("/codebuild/output/src1732223440/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]